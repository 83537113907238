<template>
  <div class="home">
    <div class="content">
      <div class="container">
        <!-- <div class="title">公司介绍</div>
        <div class="introduction">
          <span style="color: #4f9faa">HN Mini Games</span>
          帮助各种规模的，移动游戏开发者进行游戏的制作、发行和推广，促进业务的发展和增长。
        </div>-->
        <!-- <div class="title">特色产品</div> -->
        <div class="product">
          <ul>
            <li v-for="item of game" :key="item.id">
              <div class="game">
                <div class="game_icon">
                  <img :src="item.icon" />
                </div>
                <div class="game_text">
                  <h4 class="game_title">{{ item.title }}</h4>
                  <p class="game_introduce" v-html="item.introduce"></p>
                </div>
              </div>
              <div style="text-align: right;">
                <a :href="item.appUrl" title="下载app">
                  <img :src="require('@/assets/img/game1/download.png')" style="width: 80px;" />
                </a>
              </div>
              <div class="game_img" :class="{ game_img2: item.id == 6 }">
                <img v-for="(item, index) of item.gameImg" :key="index" :src="item" />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="cooperation">
        <div class="text_1">合作心声</div>
        <div class="text_2">助力开发者，成就榜首梦</div>
        <div class="text_2">改变与成长，共创繁荣共享丰盛</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      game: [
        {
          id: 1,
          icon: require('@/assets/img/game1/icon.png'),
          title: '《小兵大作战手游》',
          introduce:
            '<span style="display: inline-block;width: 2em;"></span>用三国武将进行无双割草的超解压IO游戏！你可以自由选择不同的远程近战武器，配合随机获取、无限叠加的技能，在众多的关卡模式下，单手操作，独霸天下，一个人消灭千军万马！',
          appUrl: 'https://u8sdk.hnminigames.fun/apk/xbdzz/3400573_20250206172424_com.hr.xbdzz.server1_3.0.3_20250206171759.apk',
          gameImg: [
            require('@/assets/img/game1/game1.jpg'),
            require('@/assets/img/game1/game2.jpg'),
            require('@/assets/img/game1/game3.jpg'),
          ]
        }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.introduction {
  padding: 30px 50px;
  text-indent: 2em;
  font-size: 28px;
  border-bottom: 4px solid @blColor;
}
.title {
  margin-top: 36px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: rgb(85, 85, 85);
}
.cooperation {
  width: 100%;
  background: url(~@/assets/img/bg.jpg) no-repeat;
  background-position: center;
  text-align: center;
  padding: 120px 0 240px;
  color: #fff;
  .text_1 {
    font-size: 48px;
  }
  .text_2 {
    margin-top: 30px;
    font-size: 42px;
  }
}
.product {
  li {
    padding: 50px 0 100px 0;
    border-bottom: 4px solid @blColor;
    &:last-child {
      border-bottom: 0px solid @blColor;
    }
    .game {
      display: flex;
      .game_icon {
        margin: 40px;
        border-radius: 100%;
        overflow: hidden;
        width: 280px;
        height: 280px;
        img {
          width: 280px;
          height: 280px;
          // border-radius: 100%;
          // background-position: center 100%;
          // object-fit: cover;
          background-size: 100% 100%;
          transform: scale(1.1);
          transition: all 0.6s;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
      .game_text {
        width: 860px;
        padding-top: 40px;
        padding-left: 80px;
        .game_title {
          font-size: 28px;
          opacity: 1;
          font-weight: bold;
        }
        .game_introduce {
          margin-top: 20px;
          font-size: 24px;
          opacity: 1;
          font-weight: normal;
        }
      }
    }
    .game_img {
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      img {
        width: 386px;
        height: 686px;
        margin-top: 20px;
        margin-right: 20px;
        &:nth-child(3n) {
          margin-right: 0px;
        }
      }
    }
    .game_img2 {
      img {
        height: 217px;
      }
    }
  }
}
</style>
